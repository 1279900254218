import Vue from 'vue';
import Vuex from 'vuex';
import firebase from '@/plugins/firebase';
import { vuexfireMutations, firebaseAction } from 'vuexfire';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);
const rtdb = firebase.database();

export default new Vuex.Store({
  state: {
    user: null,
    authUser: null,
    gameData: {},
    gameId: null,
  },
  mutations: {
    ...vuexfireMutations,
    onAuthStateChanged(state, user) {
      state.authUser = user; //firebaseが返したユーザー情報
    },
  },
  actions: {
    bindGameData: firebaseAction(
      ({ bindFirebaseRef, unbindFirebaseRef, state, getters }, gameId) => {
        unbindFirebaseRef('gameData');
        state.gameData = {};
        if (!getters.uid) {
          return;
        }
        bindFirebaseRef('gameData', rtdb.ref(`games/${gameId}`)).catch(e => {
          console.error(e);
          alert(
            '問題が発生しました。電波の良いところでもう一度お試しください。'
          );
        });
        state.gameId = gameId;
        console.log('game data bound');
      }
    ),
    bindUser: firebaseAction(
      ({ bindFirebaseRef, unbindFirebaseRef }, userId) => {
        unbindFirebaseRef('user');
        bindFirebaseRef('user', rtdb.ref(`users/${userId}`));
        console.log('user data bound');
      }
    ),
    unbindUser: firebaseAction(({ unbindFirebaseRef }) => {
      unbindFirebaseRef('user');
    }),
  },
  getters: {
    user(state) {
      return state.user;
    },
    photoURL(state) {
      if (state.authUser?.photoURL) {
        return state.authUser?.photoURL;
      }
      for (const pd of state.authUser?.providerData ?? []) {
        if (pd.photoURL) {
          return pd.photoURL;
        }
      }
    },
    uid(state) {
      return state.authUser?.uid;
    },
    // 自分以外の作品をいじるときはisAdminがtrueとなる
    isAdmin(state) {
      return (
        state.authUser?.email?.endsWith('@sally-inc.jp') &&
        state.gameId &&
        !Object.keys(state.user?.edit ?? {}).includes(state.gameId)
      );
    },
    isSignedIn(state) {
      return state.authUser != null;
    },
    gameId(state) {
      return state.gameId;
    },
    gameData: state => state.gameData,
  },
  plugins: [createPersistedState()],
});
